import axios from 'axios';
import { Observation, PaginatedResponse, ObservationFilters } from '../types/models';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const getObservations = async (filters?: ObservationFilters): Promise<PaginatedResponse<Observation>> => {
  try {
    const params = new URLSearchParams();
    
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== '') {
          if (key === 'species') {
            params.append('species__name_sci', value);
          } else if (key === 'place') {
            params.append('place__city__icontains', value);
          } else {
            params.append(key, value.toString());
          }
        }
      });
    }

    const response = await axios.get<PaginatedResponse<Observation>>(`${BASE_URL}/api/observations/`, { 
      params,
      headers: {
        'Accept': 'application/json',
      }
    });
    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('API Error:', error.message);
    }
    throw error;
  }
};