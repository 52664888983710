import React, { useState, useCallback } from 'react';
import { 
  Box, 
  IconButton, 
  Typography,
  Card,
  CardContent
} from '@mui/material';
import { 
  PlayArrow, 
  Pause
} from '@mui/icons-material';
import WavesurferPlayer from '@wavesurfer/react';

interface AudioPlayerProps {
  src: string;
  title?: string;
  globalVolume: number;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, title, globalVolume }) => {
  const [wavesurfer, setWavesurfer] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const onReady = useCallback((ws: any) => {
    setWavesurfer(ws);
    setDuration(ws.getDuration());
    ws.setVolume(globalVolume);
  }, [globalVolume]);

  React.useEffect(() => {
    if (wavesurfer) {
      wavesurfer.setVolume(globalVolume);
    }
  }, [globalVolume, wavesurfer]);

  const handlePlayPause = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  }, [wavesurfer]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Card variant="outlined">
      <CardContent>
        {title && (
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <IconButton onClick={handlePlayPause}>
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
          <Box sx={{ flex: 1, mx: 2 }}>
            <WavesurferPlayer
              height={50}
              waveColor="rgba(25, 118, 210, 0.4)"
              progressColor="rgb(25, 118, 210)"
              url={src}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onReady={onReady}
              cursorColor="rgb(25, 118, 210)"
              cursorWidth={1}
              barWidth={2}
              barGap={1}
              barRadius={2}
              normalize
              mediaControls={false}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Typography variant="caption">{formatTime(currentTime)}</Typography>
              <Typography variant="caption">{formatTime(duration)}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AudioPlayer;