import React, { useState } from 'react';
import { AppBar, Box, Button, Container, Collapse, IconButton, List, ListItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

const navItems = [
  { key: "etusivu", href: "/", label: "Etusivu" },
  { key: "kuvat", href: "/kuvat", label: "Kuvat" },
  { key: "aanet", href: "/aanet", label: "Äänet" },
  { key: "havainnot", href: "/havainnot", label: "Havainnot" },
  { key: "yhteydenotot", href: "/yhteydenotot", label: "Yhteydenotot" }
];

const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <AppBar position="static" sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderRadius: 2,
      }}>
        <Container maxWidth="lg">
          <Toolbar sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '64px'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { sm: 'none' },
                  color: 'black'
                }}
              >
                <MenuIcon />
              </IconButton>

              <Box component={RouterLink} to="/" sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                gap: 2
              }}>
                <Box component="img"
                  src="/images/tarsiger_logo.svg"
                  alt="Tarsiger Logo"
                  sx={{ height: 50 }}
                />
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'black',
                      fontWeight: 500
                    }}
                  >
                    Tarsiger
                  </Typography>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontSize: '0.9rem',
                      ml: 0.5
                    }}
                  >
                    .com
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{
              display: { xs: 'none', sm: 'flex' },
              gap: 1.5
            }}>
              {navItems.map((item) => (
                <Button
                  key={item.key}
                  component={RouterLink}
                  to={item.href}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',

                    color: 'black',
                    px: 1.5,
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&.active': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Toolbar>

          <Box sx={{ display: { sm: 'none' } }}>
            <Collapse in={mobileOpen}>
              <List sx={{
                width: '100%',
                bgcolor: 'white',
                py: 0
              }}>
                {navItems.map((item) => (
                  <ListItem
                    key={item.key}
                    disablePadding
                    sx={{
                      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Button
                      component={RouterLink}
                      to={item.href}
                      onClick={handleDrawerToggle}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        color: 'black',
                        display: 'block',
                        width: '100%',
                        textAlign: 'left',
                        py: 1.5,
                        px: 2,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      {item.label}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        </Container>
      </AppBar>
    </Box >
  );
};

export default Navbar;