import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { 
  Box, 
  Card, 
  CardContent, 
  Grid, 
  TextField, 
  MenuItem,
  Button,
  Typography,
  Container 
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { getSoundSets } from '../api/soundSet';
import { SoundSetFilters } from '../types/models';
import SoundSetRow from '../components/SoundSetRow';
import VolumeControl from '../components/VolumeControl';
import { Dayjs } from 'dayjs';

interface FormValues extends Omit<SoundSetFilters, 'date_from' | 'date_to'> {
  date_from: Dayjs | null;
  date_to: Dayjs | null;
}

const GENDER_OPTIONS = [
  { value: 'male', label: 'Koiras' },
  { value: 'female', label: 'Naaras' },
  { value: 'unknown', label: 'Ei tiedossa' }
];

const AGE_OPTIONS = [
  { value: 'adult', label: 'Aikuinen' },
  { value: 'immature', label: 'Nuori' },
  { value: 'juvenile', label: 'Juveniili' },
  { value: 'unknown', label: 'Ei tiedossa' }
];

const SOUND_TYPE_OPTIONS = [
  { value: 'song', label: 'Laulu' },
  { value: 'call', label: 'Kutsu' },
  { value: 'other', label: 'Muu' }
];

const defaultValues: FormValues = {
  species: '',
  place: '',
  gender: '',
  age: '',
  sound_type: '',
  date_from: null,
  date_to: null,
  country: ''
};

const SoundSets: React.FC = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues
  });
  
  const [activeFilters, setActiveFilters] = React.useState<SoundSetFilters>();
  const [globalVolume, setGlobalVolume] = React.useState(0.7);

  const { data, isLoading, error } = useQuery({
    queryKey: ['soundSets', activeFilters],
    queryFn: () => getSoundSets(activeFilters)
  });

  const onSubmit = (formData: FormValues) => {
    const cleanedData = Object.fromEntries(
      Object.entries({
        ...formData,
        date_from: formData.date_from?.format('YYYY-MM-DD'),
        date_to: formData.date_to?.format('YYYY-MM-DD')
      }).filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    );
    
    setActiveFilters({ ...cleanedData } as SoundSetFilters);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ py: 4 }}>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Haku
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="species"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Laji"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="place"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Paikka"
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="sound_type"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Äänen tyyppi"
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="">Kaikki</MenuItem>
                          {SOUND_TYPE_OPTIONS.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Sukupuoli"
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="">Kaikki</MenuItem>
                          {GENDER_OPTIONS.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="age"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Ikä"
                          fullWidth
                          size="small"
                        >
                          <MenuItem value="">Kaikki</MenuItem>
                          {AGE_OPTIONS.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="date_from"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label="Päivämäärä alkaen"
                          value={field.value}
                          onChange={(newValue) => field.onChange(newValue)}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      name="date_to"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label="Päivämäärä asti"
                          value={field.value}
                          onChange={(newValue) => field.onChange(newValue)}
                          slotProps={{ textField: { size: 'small', fullWidth: true } }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                    <Button onClick={() => reset(defaultValues)}>
                      Tyhjennä
                    </Button>
                    <Button variant="contained" type="submit">
                      Hae
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

          {isLoading ? (          
              <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">Error loading results</Typography>
          ) : data?.results.length === 0 ? (
            <Typography>No results found</Typography>
          ) : (
            <Grid container spacing={3} direction="column">
              {data?.results.map(soundSet => (
                <Grid item key={soundSet.id}>
                  <SoundSetRow 
                    soundSet={soundSet} 
                    globalVolume={globalVolume}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
      <VolumeControl
        volume={globalVolume}
        onChange={setGlobalVolume}
      />
    </>
  );
};

export default SoundSets;