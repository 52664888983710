import React from 'react';
import { Box, Container } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ImageSets from './pages/ImageSets';
import 'dayjs/locale/fi';
import './App.css';
import SoundSets from './pages/SoundSets';
import Observations from './pages/Observation';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
        <BrowserRouter>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/kuvat" element={<ImageSets />} />
              <Route path="/aanet" element={<SoundSets />} />
              <Route path="/havainnot" element={<Observations />} />
            </Routes>
          </Box>
        </BrowserRouter>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

export default App;