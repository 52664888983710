import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0342ad',
    },
    secondary: {
      main: '#ffab40',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",  // Default font for body text
    h1: {
      fontFamily: "Inter, serif",
    },
    h2: {
      fontFamily: "Inter, serif",
    },
    h3: {
      fontFamily: "Inter, serif",
    },
    h4: {
      fontFamily: "Inter, serif",
    },
    h5: {
      fontFamily: "Inter, serif",
    },
    h6: {
      fontFamily: "Inter, serif",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);
export default theme;
