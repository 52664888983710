import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Chip } from '@mui/material';
import { SoundSet } from '../types/models';
import { format } from 'date-fns';
import AudioPlayer from './AudioPlayer';

interface SoundSetRowProps {
  soundSet: SoundSet;
  globalVolume: number;
}

const SoundSetRow: React.FC<SoundSetRowProps> = ({ soundSet, globalVolume }) => {
  const mainSpecies = soundSet.species[0];
  const secondarySpecies = soundSet.species.slice(1) ?? [];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6" gutterBottom>
              {mainSpecies.name_sci}
              {secondarySpecies.length > 0 && ` / ${secondarySpecies[0].name_sci}`}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {[
                mainSpecies.name_en,
                secondarySpecies[0]?.name_en,
                mainSpecies.name_fi,
                secondarySpecies[0]?.name_fi
              ]
                .filter(Boolean)
                .join(' • ')}
            </Typography>
            {mainSpecies.name_abbr && (
              <Typography variant="body2" color="text.secondary">
                {mainSpecies.name_abbr}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            {soundSet.sounds.map((sound, index) => (
              <Box key={sound.id} sx={{ mb: 2 }}>
                <AudioPlayer 
                  src={sound.audio} 
                  title={`Recording ${index + 1}`}
                  globalVolume={globalVolume}
                />
              </Box>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
              <Chip size="small" label={soundSet.sound_type} color="primary" />
              {soundSet.gender !== 'unknown' && (
                <Chip size="small" label={soundSet.gender} />
              )}
              {soundSet.age !== 'unknown' && (
                <Chip size="small" label={soundSet.age} />
              )}
              {soundSet.status !== 'unknown' && (
                <Chip size="small" label={soundSet.status} />
              )}
              {soundSet.is_weird && (
                <Chip size="small" label="Weird" color="warning" />
              )}
            </Box>
            <Typography variant="body2">
              {soundSet.place.city}, {soundSet.place.province && `${soundSet.place.province}, `}{soundSet.place.country} • 
              {format(new Date(soundSet.date_recorded), 'dd.MM.yyyy')}
              {soundSet.coordinates && ` • ${soundSet.coordinates}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ©{soundSet.recorder} • {soundSet.equipment}
            </Typography>
            {soundSet.notes && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {soundSet.notes}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SoundSetRow;