import React from 'react';
import { Box, Card, CardContent, Container, Grid, Grid2, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import InfoTabs from '../components/InfoTabs';
import LatestImageSets from '../components/LatestImageSets';

const Home: React.FC = () => {
  const [tabValue, setTabValue] = React.useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const regionStats = [
    { region: 'Suomi', images: '1474', species: '651' },
    { region: 'WP', images: '39690', species: '584' },
    { region: 'Maailma', images: '793', species: '606' },
  ];

  const latestObservations = [
    { region: 'Suomi', date: '5.11.2024' },
    { region: 'Viro', date: '23.10.2024' },
    { region: 'WP', date: '3.11.2024' },
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{
        position: 'relative',
        backgroundImage: 'url(images/background.jpg)',
        backgroundSize: 'cover',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(255,255,255,0%) 95%, rgba(255,255,255,1) 100%)',
          pointerEvents: 'none'
        }
      }}>
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Container sx={{ pt: 10 }}>
            <Box sx={{ mb: 10, color: 'white' }}>
              <Typography variant="h1" sx={{
                fontSize: '3.5rem',
                fontWeight: 'bold',
                mb: 2
              }}>
                Suomen suosituin
              </Typography>
              <Typography variant="h1" sx={{
                fontSize: '3.5rem',
                fontWeight: 'bold'
              }}>
                lintusivusto
              </Typography>
            </Box>

            <Card sx={{ mb: 3, p: 3 }} elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    Ajankohtaista
                  </Typography>
                  <Typography paragraph>
                    Tämä lintuhavaintojen esittelypalvelu tarjoaa liki 40 000 kuvaa useista tuhansista havainnoista ja lähes kaikista Suomessa ja lähialueille havaituista lajeista. Kuvien joukossa on kokeneiden kuvaajien mestariteoksia ja kaikista pohjoisimmista kuvattujen lajien havainnointiolosuhteista on. Suomen jälkeen WP-alueelta, satunnaisesti havaintoja yritetään niiden laadukkaasta tasolta. Myös mp3-muotoisia äänitteitä löytyy sivustolta runsaasti valikoima.
                  </Typography>
                  <Typography paragraph>
                    Sivuston uudistumiseen tähtäävä rahoituskampanja on päättynyt onnistuneesti. Yhteensä 234 yksityishenkilöä saatiin kasaan hiukset 9770 euroa, mikä ylittää tavoitteen komeasti! Tuhummin kiitokset kaikille kirjoittamiselle! Uuden ja uljan tarsiger.fi:n kehitystyö on jo aloitettu ja valmista odotetaan tänä syksynä! käyttöön ja hajanvuoden aikana.
                  </Typography>
                  <Typography>
                    Tarsiger.com toivottaa kaikille hyviä lintuhetkiä!
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Statistiikat
                  </Typography>
                  <Table size="small" sx={{ mb: 3 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Kuvia</TableCell>
                        <TableCell align="right">Lajeja</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {regionStats.map((row) => (
                        <TableRow key={row.region}>
                          <TableCell>{row.region}</TableCell>
                          <TableCell align="right">{row.images}</TableCell>
                          <TableCell align="right">{row.species}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Typography variant="subtitle2" gutterBottom>
                    Uusimmat havainnot
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {latestObservations.map((obs) => (
                        <TableRow key={obs.region}>
                          <TableCell>{obs.region}:</TableCell>
                          <TableCell align="right">{obs.date}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 3 }}>
                {[1, 2, 3, 4, 5].map((sponsor) => (
                  <Grid item xs={12} sm={2.4} key={sponsor}>
                    <Box sx={{
                      bgcolor: 'grey.300',
                      height: 60,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1
                    }}>
                      <Typography>LOGO</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Container>
        </Box>
      </Box>

      <Container>
        <Grid container spacing={4} sx={{ my: 8 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ pr: { md: 4 } }}>
              <Typography variant="h6" gutterBottom>
                Meistä
              </Typography>
              <Typography paragraph>
                Tarsiger on perustettu toukokuussa 2002 esittelemään Suomessa tavattuja lintuja. Sivuston viralliset lintuhavainnot valitaan normaalista poiketen erilaisiksi. Kuvien sivusto on ulkoasultaan on taustatietoa ja tuttuilta se löytyä yhtä tarkemmissa puolinäyttäisestä, ja Tarsiger edullisimpiin siis missäsi petini palvella. Toki kuvat ja muuta materiaalista otetaan tärkelysi miksi voidaan myös porataan ulkoasopettiin henkilöistä ks. eriyen.
              </Typography>
              <Typography paragraph>
                Tarsiger-sivusto jakaa tekevät katua omia rahvityksenä, mikä mahdollistaa sivujen nopean päivityksen - uusia kuvia tulee joka päivä. Havaintoja piirretää Mike Brown.
              </Typography>
              <Typography>
                Kuvien tallennetmin noudattaa Howard & Moore:n vuosista ilmestynyttä järjestystapaa. Lintu, Howard ks. June 2, 2008. WP-lajistoon osalta noudattelaan AERC:n täfautuksia. Poikkeavahinta Lucifolasta siirretystä Tarsigeretta on viritelty(A Tarsiger nyrkkeilä, jota on jäljetty otetellaan verkkalle sukunnisvoivat!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/images/tarsiger.png"
              alt="Tarsiger Bird"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transform: 'scaleX(-1)',
                borderRadius: 2
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ mb: 4, p: 3 }}>
          <Typography variant="h6" sx={{ mb: 4 }} gutterBottom>
            Uusimmat kuvat
          </Typography>
          <LatestImageSets />
        </Box>
      </Container>
    </Box>
  );
};

export default Home;