import React from 'react';
import { Modal, IconButton, Box } from '@mui/material';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';

interface ImageViewerProps {
  open: boolean;
  onClose: () => void;
  images: { id: number; image: string }[];
  currentIndex: number;
  onNext: () => void;
  onPrevious: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  open,
  onClose,
  images,
  currentIndex,
  onNext,
  onPrevious
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.9)'
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: 'white',
            zIndex: 1
          }}
        >
          <Close />
        </IconButton>

        {/* Navigation buttons */}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onPrevious();
          }}
          sx={{
            position: 'absolute',
            left: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            zIndex: 1
          }}
        >
          <ChevronLeft />
        </IconButton>

        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onNext();
          }}
          sx={{
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            zIndex: 1
          }}
        >
          <ChevronRight />
        </IconButton>

        {/* Image */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={images[currentIndex].image}
            alt=""
            style={{
              maxWidth: '90%',
              maxHeight: '90vh',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageViewer;