import React, { useCallback } from 'react';
import { 
  Box, 
  IconButton, 
  Slider, 
  Paper
} from '@mui/material';
import { 
  VolumeUp, 
  VolumeDown,
  VolumeOff 
} from '@mui/icons-material';

interface VolumeControlProps {
  volume: number;
  onChange: (value: number) => void;
}

const VolumeControl: React.FC<VolumeControlProps> = ({ volume, onChange }) => {
  const handleVolumeChange = useCallback((_: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  }, [onChange]);

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderRadius: 2,
        zIndex: 1000,
        bgcolor: 'background.paper',
        boxShadow: 3,
      }}
    >
      <IconButton 
        onClick={() => onChange(volume === 0 ? 0.7 : 0)} 
        size="small"
        sx={{ color: 'primary.main' }}
      >
        {volume === 0 ? <VolumeOff /> : volume < 0.5 ? <VolumeDown /> : <VolumeUp />}
      </IconButton>
      <Slider
        value={volume}
        min={0}
        max={1}
        step={0.1}
        onChange={handleVolumeChange}
        aria-label="volume-slider"
        sx={{ 
          width: 100,
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
          }
        }}
      />
    </Paper>
  );
};

export default VolumeControl;