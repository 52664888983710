import { useQuery } from '@tanstack/react-query';
import { getImageSets } from '../api/imageSet';
import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import ImageSetRow from './ImageSetRow';

const LoadingSkeleton = () => (
  <Grid container spacing={3} direction="column">
    {[1, 2, 3].map((i) => (
      <Grid item key={i}>
        <Card>
          <CardContent>
            <Skeleton variant="rectangular" height={150} sx={{ mb: 2 }} />
            <Skeleton variant="text" sx={{ mb: 1 }} />
            <Skeleton variant="text" width="60%" />
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);

const LatestImageSets: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['latestImageSets'],
    queryFn: () => getImageSets({ page: 1 }),
    select: (data) => data.results.slice(0, 5),
  });

  if (isLoading) return <LoadingSkeleton />;

  if (error || !data) {
    return <Typography color="error">Failed to load latest images</Typography>;
  }

  return (
    <Grid container spacing={3} direction="column">
      {data.map((imageSet) => (
        <Grid item key={imageSet.id}>
          <ImageSetRow imageSet={imageSet} />
        </Grid>
      ))}
    </Grid>
  );
};

export default LatestImageSets;