import React, { useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, Chip, Divider } from '@mui/material';
import { ImageSet } from '../types/models';
import { format } from 'date-fns';
import ImageViewer from './ImageViewer';

interface ImageSetRowProps {
  imageSet: ImageSet;
}

const ImageSetRow: React.FC<ImageSetRowProps> = ({ imageSet }) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setViewerOpen(true);
  };

  const handleClose = () => setViewerOpen(false);
  const handleNext = () => setCurrentImageIndex(prev =>
    prev === imageSet.images.length - 1 ? 0 : prev + 1
  );
  const handlePrevious = () => setCurrentImageIndex(prev =>
    prev === 0 ? imageSet.images.length - 1 : prev - 1
  );

  const mainSpecies = imageSet.species[0]; // Primary species
  const secondarySpecies = imageSet.species.slice(1) ?? [];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mb: 2, overflow: 'hidden' }}>
            {imageSet.images.map((image, index) => (
              <Box
                key={image.id}
                onClick={() => handleImageClick(index)}
                sx={{
                  width: 200,
                  height: 150,
                  flexShrink: 0,
                  backgroundImage: `url(${image.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.9,
                    transform: 'scale(1.02)',
                    transition: 'all 0.2s ease-in-out'
                  },
                  overflowY: 'hidden'
                }}
              />
            ))}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" gutterBottom>
                {mainSpecies.name_sci}
                {secondarySpecies.length > 0 && ` / ${secondarySpecies[0].name_sci}`}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom sx={{ fontSize: '0.8rem' }}>
                {[
                  mainSpecies.name_en,
                  secondarySpecies[0]?.name_en,
                  mainSpecies.name_fi,
                  secondarySpecies[0]?.name_fi
                ]
                  .filter(Boolean)
                  .join(' • ')}
              </Typography>
              {mainSpecies.name_abbr && (
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                  {mainSpecies.name_abbr}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {imageSet.gender !== 'unknown' && (
                  <Chip size="small" label={imageSet.gender} />
                )}
                {imageSet.age !== 'unknown' && (
                  <Chip size="small" label={imageSet.age} />
                )}
                {imageSet.plumage !== 'unknown' && (
                  <Chip size="small" label={imageSet.plumage} />
                )}
                {imageSet.status !== 'unknown' && (
                  <Chip size="small" label={imageSet.status} />
                )}
                {imageSet.is_weird && (
                  <Chip size="small" label="Weird" color="warning" />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {imageSet.place.city}, {imageSet.place.province && `${imageSet.place.province}, `}{imageSet.place.country} •
                {format(new Date(imageSet.date_taken), 'dd.MM.yyyy')}
                {imageSet.coordinates && ` • ${imageSet.coordinates}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ©{imageSet.photographer} • {imageSet.equipment}
              </Typography>
              {imageSet.notes && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {imageSet.notes}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ImageViewer
        open={viewerOpen}
        onClose={handleClose}
        images={imageSet.images}
        currentIndex={currentImageIndex}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default ImageSetRow;